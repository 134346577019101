import styled from "@emotion/styled";

import SvgScmpLabLogo from "./scmp-lab-logo.svg";

export const Container = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;

  color: #999999;
`;

export const StyledSvgScmpLabLogo = styled(SvgScmpLabLogo)``;
