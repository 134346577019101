import {
  Article20ThemeSignUpButtonDisplay,
  Article20ThemeSummary,
  Article20ThemeTitle,
  NewsletterVariant,
  notEmpty,
  theme,
  useResponsive,
} from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import first from "lodash/first";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "shared/components/common/base-link/context";
import { ClientSideSuspense } from "shared/components/common/client-side-suspense";
import { article as articleData, section } from "shared/data";

import { useAppBarStickyHeightValue } from "scmp-app/components/app-bar/hooks";
import { Share } from "scmp-app/components/article/article-2.0-action-widget/share";
import type { Props as ArticleLeadingProps } from "scmp-app/components/article/article-leading";
import { ImageSize } from "scmp-app/components/article/article-leading/article-leading-images/enums";
import { ArticleRelatedTopics } from "scmp-app/components/article/article-related-topics";
import type { ArticleRenderChildProps } from "scmp-app/components/article/article-render";
import { GlobalChecker } from "scmp-app/components/article/article-render/global-checker";
import { getArticleType } from "scmp-app/components/article/article-render/helpers";
import {
  useArticleBaseAdvertisingInfo,
  useMobileInlineAdSlotIsDisabledFlag,
  useReduceWhitespaceByAvailableSpace,
} from "scmp-app/components/article/article-render/hooks";
import { ArticleSpeechSkipContainer } from "scmp-app/components/article/article-speech";
import { Spacer } from "scmp-app/components/common/spacer";
import { useContentHelper } from "scmp-app/components/content/hooks";
import type { InlineAdSlotRenderProps } from "scmp-app/components/schema-render/common/inline-ad-slot/hooks";
import { SCMPLabLogo } from "scmp-app/components/scmp-lab-logo";
import { rosettaAtom } from "scmp-app/lib/rosetta/atoms";
import type { genericArticleContent$key } from "scmp-app/queries/__generated__/genericArticleContent.graphql";

import {
  ActionWidgets,
  Author,
  AuthorDetail,
  BottomCarousel,
  Columnist,
  Content,
  ContentContainer,
  Correction,
  DesktopHeaderAdSlot,
  FurtherReadingInlineContainer,
  FurtherReadingRightSectionContainer,
  GenericArticleContainer,
  HeaderContainer,
  Headline,
  Leading,
  MobileContentHeaderAd,
  PaywallContainer,
  PublishDateTime,
  ReadingTime,
  RightAvailableSpaceTracker,
  RightSectionContainer,
  RightSectionPlaceholder,
  SeriesCarousel,
  SeriesInline,
  Sponsor,
  StyledArticleContentRightSection,
  StyledArticleTrustLabel,
  StyledContentNewsletterWidget,
  SubHeadline,
  TopicAndSection,
} from "./styles";

export type Props = ArticleRenderChildProps & {
  className?: string;
  extendedHeaderComponents?: React.ReactNode;
  leadingProps?: Partial<ArticleLeadingProps>;
  reference: genericArticleContent$key;
};

export const GenericArticle: FunctionComponent<Props> = ({
  className,
  extendedHeaderComponents,
  isInlinePaywallDomChanged,
  isShowInlinePaywall,
  leadingProps,
  provider,
  reference,
}) => {
  const article = useFragment(
    graphql`
      fragment genericArticleContent on Content {
        entityId
        flag
        sections {
          value {
            entityId
          }
        }
        ...articleBaseAdvertisingInfoArticle
        ...articleRelatedTopicsContent
        ...contentNewsletterWidgetContent
        ...globalCheckerContent
        ...helpersGetArticleTypeArticle
        ...hooksContentHelperContent
        ...shareArticle20Content
      }
    `,
    reference,
  );

  const { zone } = useArticleBaseAdvertisingInfo(article);
  const { appBarStickyHeight } = useAppBarStickyHeightValue();
  const isInlineAdSlotDisabled = useMobileInlineAdSlotIsDisabledFlag();
  const isDesktopUp = useResponsive(theme.breakpoints.up("desktop"), false);
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const isSubscriber = useMemo(
    () => asyncRosettaState?.result?.user?.isSCMPSubscriber ?? false,
    [asyncRosettaState?.result?.user?.isSCMPSubscriber],
  );
  const withSticky = useMemo(() => {
    if (asyncRosettaState?.status === "success") {
      return (
        asyncRosettaState.result?.user === undefined ||
        asyncRosettaState.result?.user.isSCMPSubscriber === false
      );
    }
    return false;
  }, [asyncRosettaState?.result?.user, asyncRosettaState?.status]);

  const { isRecapArticleType } = useContentHelper(article);
  const articleType = getArticleType(article);
  const isOpinionArticle = useMemo(
    () =>
      articleType === articleData.types.opinion.entityId ||
      articleType === articleData.types.column.entityId,
    [articleType],
  );

  const isVideoArticle = useMemo(() => article.flag === articleData.flag.video, [article.flag]);

  const { reduceWhitespaceByAvailableSpaceSpec } = useReduceWhitespaceByAvailableSpace({
    firstAdSlotSize: 1000,
    isEnabled: isShowInlinePaywall !== undefined || notEmpty(isInlinePaywallDomChanged),
    specs: {
      adSlotSize: 600,
      whitespaceAllowance: 100,
      widgets: [
        {
          maxHeight: 470,
          name: "furtherReadingWidget",
        },
        {
          maxHeight: 560,
          name: "topicWidget",
          withAdSlot: !isSubscriber,
        },
        {
          maxHeight: isSubscriber ? 380 : 700,
          name: "beforeYouGoWidget",
          withAdSlot: false,
        },
        {
          maxHeight: 640,
          name: "geoLocationWidget",
          withAdSlot: false,
        },
      ],
    },
    trackers: {
      right: RightAvailableSpaceTracker,
    },
  });

  const magazineVariant = useMemo(() => {
    const sectionEntityIds =
      first(article?.sections)?.value?.map(section => section?.entityId) ?? [];
    switch (true) {
      case sectionEntityIds.includes(section.postMagazine.entityId):
        return section.postMagazine.entityId;
      case sectionEntityIds.includes(section.thisWeekInAsia.entityId):
        return section.thisWeekInAsia.entityId;
    }
  }, [article?.sections]);

  const inlineAdSlots = useMemo<InlineAdSlotRenderProps>(() => {
    if (isDesktopUp) {
      return isSubscriber
        ? {
            "7": {
              adUnit: "outstream1",
            },
          }
        : {
            "1": {
              adUnit: "outstream1",
            },
            "7": {
              adUnit: "d_native5",
            },
          };
    }
    return {
      "0": {
        adUnit: "m_banner1",
        breakpoint: theme.breakpoints.down("desktop"),
        sizes: [[300, 250]],
      },
      "1": {
        adUnit: "outstream1",
      },
      "2": {
        adUnit: "m_lrec1",
        breakpoint: theme.breakpoints.down("desktop"),
        sizes: [
          [300, 250],
          [320, 480],
        ],
      },
      "3": {
        adUnit: "m_lrec2",
        breakpoint: theme.breakpoints.down("desktop"),
        sizes: [[300, 250]],
      },
      "4": {
        adUnit: "m_lrec4d",
        breakpoint: theme.breakpoints.down("desktop"),
        isDisabled: isInlineAdSlotDisabled,
        sizes: [
          [300, 250],
          [320, 480],
        ],
      },
      "5": {
        adUnit: "m_lrec4e",
        breakpoint: theme.breakpoints.down("desktop"),
        isDisabled: isInlineAdSlotDisabled,
        sizes: [[300, 250]],
      },
      "6": {
        adUnit: "m_lrec4f",
        breakpoint: theme.breakpoints.down("desktop"),
        isDisabled: isInlineAdSlotDisabled,
        sizes: [[300, 250]],
      },
      "7": {
        adUnit: "m_native5",
        isDisabled: isInlineAdSlotDisabled,
      },
    };
  }, [isDesktopUp, isInlineAdSlotDisabled, isSubscriber]);

  return (
    <GenericArticleContainer className={className}>
      <DesktopHeaderAdSlot withSticky={withSticky}>
        {provider.adslot({
          adUnit: "d_banner1",
          autoRefreshOptions: {
            scrollBackRefreshOptions: {
              slotArticleEntityId: article.entityId,
            },
          },
          breakpoint: theme.breakpoints.up("desktop"),
          sizes: [
            [970, 250],
            [728, 90],
            [970, 90],
          ],
        })}
      </DesktopHeaderAdSlot>
      <HeaderContainer>
        {extendedHeaderComponents}
        <BaseLinkContextProvider
          customQueryParameters={{
            module: "breadcrumb",
            pgtype: "article",
          }}
        >
          <TopicAndSection>
            {provider.topic()}
            {provider.sections()}
          </TopicAndSection>
        </BaseLinkContextProvider>
        {isOpinionArticle && (
          <Columnist>
            {provider.authorAvatars({
              overlap: false,
              withoutLink: true,
            })}
            {provider.authorNames({ withLocation: false })}
          </Columnist>
        )}
        <Headline $magazineVariant={magazineVariant}>{provider.headline()}</Headline>
        <SubHeadline>{provider.subHeadline()}</SubHeadline>
        <Sponsor>
          {provider.sponsor({
            sponsorTagStyle: {
              layoutDirection: "horizontal",
            },
          })}
        </Sponsor>
        {!isVideoArticle && (
          <ReadingTime>
            {provider.readingTime()}
            <Spacer />
            <ArticleSpeechSkipContainer>
              <StyledArticleTrustLabel />
            </ArticleSpeechSkipContainer>
          </ReadingTime>
        )}
        <ActionWidgets>
          <Share position="article_header" reference={article} />
          {provider.actionWidget({ variant: "article-2.0" })}
          {provider.speechPlayer({
            variant: "article-2.0-minify",
          })}
          {provider.speechPlayerPortal({
            variant: "article-2.0-minify",
            withMinifyCleanUpButton: false,
          })}
        </ActionWidgets>
      </HeaderContainer>
      {reduceWhitespaceByAvailableSpaceSpec.right.trackerNode}

      <StyledArticleContentRightSection
        {...reduceWhitespaceByAvailableSpaceSpec.right}
        entityId={article.entityId}
        provider={provider}
      >
        {shouldRenderWidgets => (
          <>
            {shouldRenderWidgets["furtherReadingWidget"] && (
              <FurtherReadingRightSectionContainer $stickyStackedHeight={appBarStickyHeight}>
                {provider.furtherReading({
                  inlineAds: provider.adslot({
                    adUnit: "d_native2b",
                    breakpoint: theme.breakpoints.up("desktop"),
                    sizes: ["fluid"],
                  }),
                  variant: "right-hand-side",
                })}
                {provider.adslot({
                  adUnit: "d_lrec2",
                  breakpoint: theme.breakpoints.up("desktop"),
                  sizes: [
                    [300, 250],
                    [300, 600],
                  ],
                  withSticky: true,
                })}
              </FurtherReadingRightSectionContainer>
            )}

            {shouldRenderWidgets["topicWidget"] && (
              <RightSectionContainer $stickyStackedHeight={appBarStickyHeight}>
                {provider.topicWidget({
                  children: <ArticleRelatedTopics reference={article} />,
                })}
                {!isSubscriber &&
                  provider.adslot({
                    adUnit: "d_lrec3b",
                    autoRefreshOptions: {
                      scrollBackRefreshOptions: { slotArticleEntityId: article.entityId },
                    },
                    breakpoint: theme.breakpoints.up("desktop"),
                    sizes: [
                      [300, 250],
                      [300, 600],
                    ],
                    withSticky: true,
                  })}
              </RightSectionContainer>
            )}
            {shouldRenderWidgets["geoLocationWidget"] && (
              <RightSectionContainer $stickyStackedHeight={appBarStickyHeight}>
                {provider.geoLocationWidget()}
              </RightSectionContainer>
            )}
            {shouldRenderWidgets["beforeYouGoWidget"] && (
              <>
                {/** placeholder to make the position of topic widget to be evenly distributed */}
                <RightSectionPlaceholder />
                {!isSubscriber && (
                  <StyledContentNewsletterWidget
                    hideWhenNoNewsletters
                    reference={article}
                    signUpButtonDisplayComponent={<Article20ThemeSignUpButtonDisplay />}
                    summaryComponent={<Article20ThemeSummary />}
                    titleComponent={<Article20ThemeTitle />}
                    variant={NewsletterVariant.Article20}
                  />
                )}
                <RightSectionContainer $stickyStackedHeight={appBarStickyHeight}>
                  {provider.beforeYouGoWidget({
                    children: provider.scmpPollWidget({
                      adSlotProps: {
                        breakpoint: theme.breakpoints.up("desktop"),
                        zone,
                      },
                    }),
                  })}
                </RightSectionContainer>
              </>
            )}
          </>
        )}
      </StyledArticleContentRightSection>
      <Leading>
        {provider.leading({
          responsiveDescriptionVariants: {
            desktopUp: "show",
            mobileUp: "show",
            tabletUp: "show",
          },
          responsiveImageVariants: { desktopUp: "normal", mobileUp: "normal", tabletUp: "normal" },
          responsiveVariants: {
            desktopUp: ImageSize["1200x800"],
            mobileUp: ImageSize["1020x680"],
            tabletUp: ImageSize["1020x680"],
          },
          toggleTitle: true,
          ...leadingProps,
        })}
      </Leading>
      <ContentContainer>
        <Author>
          {provider.authorNames()}
          {isRecapArticleType && <SCMPLabLogo />}
        </Author>
        <PublishDateTime>{provider.publishedDate({ variant: "article-2.0" })}</PublishDateTime>

        <Correction>{provider.correction()}</Correction>
        <Content>
          {provider.body({
            extraComponentMap: {
              "inline-widget": null,
            },
            extraRenderFunctionMap: {
              "further-reading-widget": (_, keyIndex) => (
                <FurtherReadingInlineContainer key={keyIndex}>
                  {provider.furtherReading({
                    inlineAds: provider.adslot({
                      adUnit: "m_native2b",
                      breakpoint: theme.breakpoints.between("mobile", "desktop"),
                      isDisabled: isInlineAdSlotDisabled,
                      sizes: ["fluid"],
                    }),
                    variant: "inline",
                  })}
                </FurtherReadingInlineContainer>
              ),
            },
            inlineAdSlots,
            preprocessSchema: (schema: Schema) => {
              const newSchema = [...schema];
              const targetAdSlotTag = isSubscriber ? "5" : "4";
              const targetIndex = newSchema.findIndex(
                node => node.type === "inline-ad-slot" && node.attribs?.tag === targetAdSlotTag,
              );

              if (targetIndex === -1) {
                const lastAdSlot = newSchema.findLastIndex(node => node.type === "inline-ad-slot");
                newSchema.splice(lastAdSlot + 1, 0, { type: "further-reading-widget" });
              } else {
                newSchema.splice(targetIndex + 1, 0, { type: "further-reading-widget" });
              }

              return newSchema;
            },
          })}
        </Content>

        <PaywallContainer>{provider.paywall()}</PaywallContainer>

        <AuthorDetail>{provider.authorDetails()}</AuthorDetail>

        <BottomCarousel>{provider.bottomCarouselWidget(provider)}</BottomCarousel>

        <SeriesInline>{provider.series({ variant: "inline" })}</SeriesInline>

        <MobileContentHeaderAd>
          {provider.adslot({
            adUnit: "m_banner3",
            autoRefreshOptions: {
              scrollBackRefreshOptions: {
                slotArticleEntityId: article.entityId,
              },
            },
            breakpoint: theme.breakpoints.down("desktop"),
            sizes: [
              [320, 100],
              [320, 50],
              [300, 100],
              [300, 50],
            ],
            withBackground: true,
            withLabel: true,
          })}
        </MobileContentHeaderAd>

        <ClientSideSuspense>
          <GlobalChecker reference={article}>
            <SeriesCarousel>{provider.series({ variant: "carousel" })}</SeriesCarousel>
          </GlobalChecker>
        </ClientSideSuspense>
      </ContentContainer>
    </GenericArticleContainer>
  );
};

GenericArticle.displayName = "GenericArticle";
