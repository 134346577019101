/**
 * @generated SignedSource<<bad323a17f6f1b538915ebdcda9db7c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type genericArticleContent$data = {
  readonly entityId: string;
  readonly flag: string | null | undefined;
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"articleBaseAdvertisingInfoArticle" | "articleRelatedTopicsContent" | "contentNewsletterWidgetContent" | "globalCheckerContent" | "helpersGetArticleTypeArticle" | "hooksContentHelperContent" | "shareArticle20Content">;
  readonly " $fragmentType": "genericArticleContent";
};
export type genericArticleContent$key = {
  readonly " $data"?: genericArticleContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"genericArticleContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flag",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ArticleTypeSegment",
        "kind": "LinkedField",
        "name": "types",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticleType",
            "kind": "LinkedField",
            "name": "value",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Article",
    "abstractKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "genericArticleContent",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SectionSegment",
      "kind": "LinkedField",
      "name": "sections",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "value",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleBaseAdvertisingInfoArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleRelatedTopicsContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentNewsletterWidgetContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "globalCheckerContent"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersGetArticleTypeArticle",
      "selections": (v3/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "hooksContentHelperContent",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersSanitizeArticleTypeEntityIdsArticle",
          "selections": (v3/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersSanitizeAuthorTypesArticle",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Author",
                  "kind": "LinkedField",
                  "name": "authors",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "types",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Article",
              "abstractKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersSanitizedAuthorEntityIdsArticle",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Author",
                  "kind": "LinkedField",
                  "name": "authors",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "type": "Article",
              "abstractKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        (v1/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "shareArticle20Content"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "bb3a38c554281eccd808c349274f4d8f";

export default node;
