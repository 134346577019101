import { theme, useResponsive } from "@product/scmp-sdk";
import { useMemo } from "react";

import { useContentReadMoreContext } from "scmp-app/components/content/content-read-more";

export const useMobileInlineAdSlotIsDisabledFlag = () => {
  const { isReadMoreClicked } = useContentReadMoreContext();
  const isMobile = useResponsive(theme.breakpoints.only("mobile"), true);

  return useMemo(() => {
    if (!isMobile) return false;
    return !isReadMoreClicked;
  }, [isReadMoreClicked, isMobile]);
};
