import type { FunctionComponent } from "react";

import { Container, StyledSvgScmpLabLogo } from "./styles";

export type Props = {
  className?: string;
};

export const SCMPLabLogo: FunctionComponent<Props> = ({ className }) => (
  <Container className={className}>
    by
    <StyledSvgScmpLabLogo />
  </Container>
);

SCMPLabLogo.displayName = "SCMPLabLogo";
